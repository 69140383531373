import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/atlassian/pipelines/agent/build/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { useState } from "react";
import { Playground, Props } from "docz";
import { PackageMetadata } from "@happeo/docs-tools";
import pkg from "../package.json";
import { ActionsMenu, FilterMenu } from "../src/index";
import { actions, actionsWithIcons } from "./examples";
import { IconChat, IconArrowDropDown, IconEdit, IconArchive, IconHeartFill, IconStar, IconFastfood, IconGoogleMeetAppColored, IconMicrosoftTeamsAppColored, IconGmailAppColored, IconSlackAppColored, IconGoogleChatAppColored } from "@happeouikit/icons";
import { ButtonPrimary, ButtonSecondary } from "@happeouikit/buttons";
import { DebugHappeoThemeProvider } from "@happeouikit/theme";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "menus"
    }}>{`Menus`}</h1>
    <PackageMetadata pkg={pkg} mdxType="PackageMetadata" />
    <DebugHappeoThemeProvider mdxType="DebugHappeoThemeProvider" />
    <h2 {...{
      "id": "actions-menu"
    }}>{`Actions Menu`}</h2>
    <h2 {...{
      "id": "accessibility"
    }}>{`Accessibility`}</h2>
    <p>{`While the component itself is functionally accessible, it's crucial for developers using ActionMenu to consider proper announcement of state changes upon user interaction.
When a user clicks on an item within the ActionMenu, it's important to announce this change to screen reader users and potentially improve clarity for sighted users as well.
To accomplish this, call announceAriaLive function, which is availabe in the bridge.`}</p>
    <Props of={ActionsMenu} mdxType="Props" />
    <h3 {...{
      "id": "menu-right"
    }}>{`Menu right`}</h3>
    <Playground __position={3} __code={'<div>\n  <ActionsMenu\n    actions={[\n      {\n        name: \'Edit page\',\n        callback: val => console.log(\'edited\', val),\n        type: \'edit\',\n      },\n      {\n        name: \'Archive page\',\n        callback: val => console.log(\'archived\', val),\n        type: \'archive\',\n      },\n      {\n        name: \'Some external link\',\n        url: \'https://www.google.com\',\n        type: \'href\',\n      },\n      {\n        name: \'Some link with callback\',\n        type: \'href\',\n        callback: val => console.log(\'some link\', val),\n      },\n      {\n        name: \'Some link without callback\',\n        type: \'href\',\n      },\n    ]}\n    menuPosition=\"right\"\n    ariaLabel=\"Example\"\n  />\n</div>'} __scope={{
      props,
      DefaultLayout,
      useState,
      Playground,
      Props,
      PackageMetadata,
      pkg,
      ActionsMenu,
      FilterMenu,
      actions,
      actionsWithIcons,
      IconChat,
      IconArrowDropDown,
      IconEdit,
      IconArchive,
      IconHeartFill,
      IconStar,
      IconFastfood,
      IconGoogleMeetAppColored,
      IconMicrosoftTeamsAppColored,
      IconGmailAppColored,
      IconSlackAppColored,
      IconGoogleChatAppColored,
      ButtonPrimary,
      ButtonSecondary,
      DebugHappeoThemeProvider,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <div>
    <ActionsMenu actions={[{
          name: "Edit page",
          callback: val => console.log("edited", val),
          type: "edit"
        }, {
          name: "Archive page",
          callback: val => console.log("archived", val),
          type: "archive"
        }, {
          name: "Some external link",
          url: "https://www.google.com",
          type: "href"
        }, {
          name: "Some link with callback",
          type: "href",
          callback: val => console.log("some link", val)
        }, {
          name: "Some link without callback",
          type: "href"
        }]} menuPosition="right" ariaLabel="Example" mdxType="ActionsMenu" />
  </div>
    </Playground>
    <h3 {...{
      "id": "menu-left"
    }}>{`Menu left`}</h3>
    <Playground __position={4} __code={'<div>\n  <ActionsMenu actions={actions} data-tracker=\"Left Menu\" ariaLabel=\"Left\" />\n</div>'} __scope={{
      props,
      DefaultLayout,
      useState,
      Playground,
      Props,
      PackageMetadata,
      pkg,
      ActionsMenu,
      FilterMenu,
      actions,
      actionsWithIcons,
      IconChat,
      IconArrowDropDown,
      IconEdit,
      IconArchive,
      IconHeartFill,
      IconStar,
      IconFastfood,
      IconGoogleMeetAppColored,
      IconMicrosoftTeamsAppColored,
      IconGmailAppColored,
      IconSlackAppColored,
      IconGoogleChatAppColored,
      ButtonPrimary,
      ButtonSecondary,
      DebugHappeoThemeProvider,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <div>
    <ActionsMenu actions={actions} data-tracker="Left Menu" ariaLabel="Left" mdxType="ActionsMenu" />
  </div>
    </Playground>
    <h3 {...{
      "id": "menu-disabled"
    }}>{`Menu disabled`}</h3>
    <Playground __position={5} __code={'<div>\n  <ActionsMenu\n    disabled\n    icon={IconArrowDropDown}\n    actions={actions}\n    ariaLabel=\"Icon\"\n  />\n</div>'} __scope={{
      props,
      DefaultLayout,
      useState,
      Playground,
      Props,
      PackageMetadata,
      pkg,
      ActionsMenu,
      FilterMenu,
      actions,
      actionsWithIcons,
      IconChat,
      IconArrowDropDown,
      IconEdit,
      IconArchive,
      IconHeartFill,
      IconStar,
      IconFastfood,
      IconGoogleMeetAppColored,
      IconMicrosoftTeamsAppColored,
      IconGmailAppColored,
      IconSlackAppColored,
      IconGoogleChatAppColored,
      ButtonPrimary,
      ButtonSecondary,
      DebugHappeoThemeProvider,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <div>
    <ActionsMenu disabled icon={IconArrowDropDown} actions={actions} ariaLabel="Icon" mdxType="ActionsMenu" />
  </div>
    </Playground>
    <h3 {...{
      "id": "custom-menu-button"
    }}>{`Custom menu button`}</h3>
    <Playground __position={6} __code={'<div>\n  <ActionsMenu\n    actions={actions}\n    menuPosition=\"right\"\n    text=\"Menu button\"\n    ariaLabel=\"test\"\n  />\n  <ActionsMenu\n    actions={actions}\n    menuPosition=\"right\"\n    button={ButtonSecondary}\n    text=\"Custom menu with icon\"\n    icon={IconArrowDropDown}\n    iconPosition=\"right\"\n    ariaLabel=\"test\"\n  />\n  <ActionsMenu\n    actions={actions}\n    menuPosition=\"right\"\n    button={ButtonSecondary}\n    text=\"Small menu with icon\"\n    icon={IconArrowDropDown}\n    iconPosition=\"right\"\n    size=\"small\"\n    ariaLabel=\"test\"\n  />\n</div>'} __scope={{
      props,
      DefaultLayout,
      useState,
      Playground,
      Props,
      PackageMetadata,
      pkg,
      ActionsMenu,
      FilterMenu,
      actions,
      actionsWithIcons,
      IconChat,
      IconArrowDropDown,
      IconEdit,
      IconArchive,
      IconHeartFill,
      IconStar,
      IconFastfood,
      IconGoogleMeetAppColored,
      IconMicrosoftTeamsAppColored,
      IconGmailAppColored,
      IconSlackAppColored,
      IconGoogleChatAppColored,
      ButtonPrimary,
      ButtonSecondary,
      DebugHappeoThemeProvider,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <div>
    <ActionsMenu actions={actions} menuPosition="right" text="Menu button" ariaLabel="test" mdxType="ActionsMenu" />
        <ActionsMenu actions={actions} menuPosition="right" button={ButtonSecondary} text="Custom menu with icon" icon={IconArrowDropDown} iconPosition="right" ariaLabel="test" mdxType="ActionsMenu" />
        <ActionsMenu actions={actions} menuPosition="right" button={ButtonSecondary} text="Small menu with icon" icon={IconArrowDropDown} iconPosition="right" size="small" ariaLabel="test" mdxType="ActionsMenu" />
  </div>
    </Playground>
    <h3 {...{
      "id": "use-icons-on-items"
    }}>{`Use icons on items`}</h3>
    <Playground __position={7} __code={'<div>\n  <ActionsMenu\n    actions={actionsWithIcons}\n    menuPosition=\"right\"\n    ariaLabel=\"test\"\n  />\n</div>'} __scope={{
      props,
      DefaultLayout,
      useState,
      Playground,
      Props,
      PackageMetadata,
      pkg,
      ActionsMenu,
      FilterMenu,
      actions,
      actionsWithIcons,
      IconChat,
      IconArrowDropDown,
      IconEdit,
      IconArchive,
      IconHeartFill,
      IconStar,
      IconFastfood,
      IconGoogleMeetAppColored,
      IconMicrosoftTeamsAppColored,
      IconGmailAppColored,
      IconSlackAppColored,
      IconGoogleChatAppColored,
      ButtonPrimary,
      ButtonSecondary,
      DebugHappeoThemeProvider,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <div>
    <ActionsMenu actions={actionsWithIcons} menuPosition="right" ariaLabel="test" mdxType="ActionsMenu" />
  </div>
    </Playground>
    <h3 {...{
      "id": "auto-hide"
    }}>{`Auto Hide`}</h3>
    <p>{`If given the autoHide prop, the menu will close if the user's cursor hovers outside of it for 0.5 seconds.`}</p>
    <Playground __position={8} __code={'<div>\n  <ActionsMenu actions={actions} autoHide ariaLabel=\"test\" />\n</div>'} __scope={{
      props,
      DefaultLayout,
      useState,
      Playground,
      Props,
      PackageMetadata,
      pkg,
      ActionsMenu,
      FilterMenu,
      actions,
      actionsWithIcons,
      IconChat,
      IconArrowDropDown,
      IconEdit,
      IconArchive,
      IconHeartFill,
      IconStar,
      IconFastfood,
      IconGoogleMeetAppColored,
      IconMicrosoftTeamsAppColored,
      IconGmailAppColored,
      IconSlackAppColored,
      IconGoogleChatAppColored,
      ButtonPrimary,
      ButtonSecondary,
      DebugHappeoThemeProvider,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <div>
    <ActionsMenu actions={actions} autoHide ariaLabel="test" mdxType="ActionsMenu" />
  </div>
    </Playground>
    <h3 {...{
      "id": "highlight-current-selection"
    }}>{`Highlight current selection`}</h3>
    <p>{`If given the selectedActionName prop, the menu will highlight that specific action (useful for switching "modes").`}</p>
    <Playground __position={9} __code={'() => {\n  const [selectedModeIndex, setSelectedModeIndex] = useState(1)\n  const modes = [\n    {\n      name: \'Mode 1\',\n      callback: () => setSelectedModeIndex(0),\n      icon: IconHeartFill,\n    },\n    {\n      name: \'Mode 2\',\n      callback: () => setSelectedModeIndex(1),\n      icon: IconStar,\n      type: \'href\',\n    },\n    {\n      name: \'Mode 3\',\n      callback: () => setSelectedModeIndex(2),\n      icon: IconFastfood,\n    },\n  ]\n  return (\n    <div>\n      <ActionsMenu\n        actions={modes}\n        selectedActionName={modes[selectedModeIndex].name}\n        text={`Selected: ${modes[selectedModeIndex].name}`}\n        icon={modes[selectedModeIndex].icon}\n        ariaLabel=\"test\"\n      />\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useState,
      Playground,
      Props,
      PackageMetadata,
      pkg,
      ActionsMenu,
      FilterMenu,
      actions,
      actionsWithIcons,
      IconChat,
      IconArrowDropDown,
      IconEdit,
      IconArchive,
      IconHeartFill,
      IconStar,
      IconFastfood,
      IconGoogleMeetAppColored,
      IconMicrosoftTeamsAppColored,
      IconGmailAppColored,
      IconSlackAppColored,
      IconGoogleChatAppColored,
      ButtonPrimary,
      ButtonSecondary,
      DebugHappeoThemeProvider,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [selectedModeIndex, setSelectedModeIndex] = useState(1);
        const modes = [{
          name: "Mode 1",
          callback: () => setSelectedModeIndex(0),
          icon: IconHeartFill
        }, {
          name: "Mode 2",
          callback: () => setSelectedModeIndex(1),
          icon: IconStar,
          type: "href"
        }, {
          name: "Mode 3",
          callback: () => setSelectedModeIndex(2),
          icon: IconFastfood
        }];
        return <div>
        <ActionsMenu actions={modes} selectedActionName={modes[selectedModeIndex].name} text={`Selected: ${modes[selectedModeIndex].name}`} icon={modes[selectedModeIndex].icon} ariaLabel="test" mdxType="ActionsMenu" />
      </div>;
      }}
    </Playground>
    <h3 {...{
      "id": "menu-with-multiple-groups"
    }}>{`Menu with multiple groups`}</h3>
    <Playground __position={10} __code={'<div>\n  <ActionsMenu\n    actions={[\n      {\n        name: \'Call Options\',\n        type: \'group\',\n        items: [\n          {\n            name: \'Google Meet\',\n            callback: val => console.log(\'Google Meet\', val),\n            type: \'edit\',\n            icon: IconGoogleMeetAppColored,\n          },\n          {\n            name: \'Microsoft Teams\',\n            callback: val => console.log(\'Microsoft Teams\', val),\n            type: \'edit\',\n            icon: IconMicrosoftTeamsAppColored,\n          },\n        ],\n      },\n      {\n        name: \'Messaging Options\',\n        type: \'group\',\n        items: [\n          {\n            name: \'Gmail\',\n            callback: val => console.log(\'Gmail\', val),\n            type: \'edit\',\n            icon: IconGmailAppColored,\n          },\n          {\n            name: \'Slack\',\n            callback: val => console.log(\'Slack\', val),\n            type: \'edit\',\n            icon: IconSlackAppColored,\n          },\n          {\n            name: \'Google Chat\',\n            callback: val => console.log(\'Google Chat\', val),\n            type: \'edit\',\n            icon: IconGoogleChatAppColored,\n          },\n        ],\n      },\n    ]}\n    menuPosition=\"right\"\n    ariaLabel=\"Example\"\n  />\n</div>'} __scope={{
      props,
      DefaultLayout,
      useState,
      Playground,
      Props,
      PackageMetadata,
      pkg,
      ActionsMenu,
      FilterMenu,
      actions,
      actionsWithIcons,
      IconChat,
      IconArrowDropDown,
      IconEdit,
      IconArchive,
      IconHeartFill,
      IconStar,
      IconFastfood,
      IconGoogleMeetAppColored,
      IconMicrosoftTeamsAppColored,
      IconGmailAppColored,
      IconSlackAppColored,
      IconGoogleChatAppColored,
      ButtonPrimary,
      ButtonSecondary,
      DebugHappeoThemeProvider,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <div>
    <ActionsMenu actions={[{
          name: "Call Options",
          type: "group",
          items: [{
            name: "Google Meet",
            callback: val => console.log("Google Meet", val),
            type: "edit",
            icon: IconGoogleMeetAppColored
          }, {
            name: "Microsoft Teams",
            callback: val => console.log("Microsoft Teams", val),
            type: "edit",
            icon: IconMicrosoftTeamsAppColored
          }]
        }, {
          name: "Messaging Options",
          type: "group",
          items: [{
            name: "Gmail",
            callback: val => console.log("Gmail", val),
            type: "edit",
            icon: IconGmailAppColored
          }, {
            name: "Slack",
            callback: val => console.log("Slack", val),
            type: "edit",
            icon: IconSlackAppColored
          }, {
            name: "Google Chat",
            callback: val => console.log("Google Chat", val),
            type: "edit",
            icon: IconGoogleChatAppColored
          }]
        }]} menuPosition="right" ariaLabel="Example" mdxType="ActionsMenu" />
  </div>
    </Playground>
    <h2 {...{
      "id": "filter-menu"
    }}>{`Filter Menu`}</h2>
    <Props of={FilterMenu} mdxType="Props" />
    <Playground mdxType="Playground">
  <div>
    <FilterMenu actions={actions} menuPosition="left" initialChecked mdxType="FilterMenu" />
  </div>
    </Playground>
    <h3 {...{
      "id": "filter-menu-with-custom-icon"
    }}>{`Filter menu with custom icon`}</h3>
    <Playground __position={12} __code={'<div>\n  <FilterMenu\n    actions={actions}\n    menuPosition=\"right\"\n    initialChecked\n    icon={IconChat}\n  />\n</div>'} __scope={{
      props,
      DefaultLayout,
      useState,
      Playground,
      Props,
      PackageMetadata,
      pkg,
      ActionsMenu,
      FilterMenu,
      actions,
      actionsWithIcons,
      IconChat,
      IconArrowDropDown,
      IconEdit,
      IconArchive,
      IconHeartFill,
      IconStar,
      IconFastfood,
      IconGoogleMeetAppColored,
      IconMicrosoftTeamsAppColored,
      IconGmailAppColored,
      IconSlackAppColored,
      IconGoogleChatAppColored,
      ButtonPrimary,
      ButtonSecondary,
      DebugHappeoThemeProvider,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <div>
    <FilterMenu actions={actions} menuPosition="right" initialChecked icon={IconChat} mdxType="FilterMenu" />
  </div>
    </Playground>
    <h3 {...{
      "id": "filter-menu-with-custom-button"
    }}>{`Filter menu with custom button`}</h3>
    <Playground __position={13} __code={'<div>\n  <FilterMenu\n    actions={actions}\n    menuPosition=\"right\"\n    initialChecked\n    text=\"Test\"\n  />\n</div>'} __scope={{
      props,
      DefaultLayout,
      useState,
      Playground,
      Props,
      PackageMetadata,
      pkg,
      ActionsMenu,
      FilterMenu,
      actions,
      actionsWithIcons,
      IconChat,
      IconArrowDropDown,
      IconEdit,
      IconArchive,
      IconHeartFill,
      IconStar,
      IconFastfood,
      IconGoogleMeetAppColored,
      IconMicrosoftTeamsAppColored,
      IconGmailAppColored,
      IconSlackAppColored,
      IconGoogleChatAppColored,
      ButtonPrimary,
      ButtonSecondary,
      DebugHappeoThemeProvider,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <div>
    <FilterMenu actions={actions} menuPosition="right" initialChecked text="Test" mdxType="FilterMenu" />
  </div>
    </Playground>
    <h3 {...{
      "id": "filter-menu-with-preselected-values"
    }}>{`Filter menu with preselected values`}</h3>
    <Playground __position={14} __code={'<div>\n  <FilterMenu\n    actions={actions}\n    menuPosition=\"right\"\n    text=\"Test\"\n    selected={[\'edit\']}\n  />\n</div>'} __scope={{
      props,
      DefaultLayout,
      useState,
      Playground,
      Props,
      PackageMetadata,
      pkg,
      ActionsMenu,
      FilterMenu,
      actions,
      actionsWithIcons,
      IconChat,
      IconArrowDropDown,
      IconEdit,
      IconArchive,
      IconHeartFill,
      IconStar,
      IconFastfood,
      IconGoogleMeetAppColored,
      IconMicrosoftTeamsAppColored,
      IconGmailAppColored,
      IconSlackAppColored,
      IconGoogleChatAppColored,
      ButtonPrimary,
      ButtonSecondary,
      DebugHappeoThemeProvider,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <div>
    <FilterMenu actions={actions} menuPosition="right" text="Test" selected={["edit"]} mdxType="FilterMenu" />
  </div>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      